import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers
import DashboardReducer from "./reducers/DashboardReducer";
import RealTimeReducer from "./reducers/RealTimeReducer";
import MetricsReducer from "./reducers/MetricsReducer";

//RootReducer
const rootReducer = combineReducers({
  dashboard: DashboardReducer,
  realTime: RealTimeReducer,
  metrics: MetricsReducer,
});

export default function generateStore() {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  return store;
}
