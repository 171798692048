import {
  GET_TOTAL_IN_COURSE,
  GET_TOTAL_IN_COURSE_EXITO,
  GET_TOTAL_IN_COURSE_ERROR,
  GET_SUMMARY_IN_COURSE,
  GET_SUMMARY_IN_COURSE_EXITO,
  GET_SUMMARY_IN_COURSE_ERROR,
  GET_BALANCE,
  GET_BALANCE_EXITO,
  GET_BALANCE_ERROR,
} from "../types/RealTime";

const initialState = {
  realTimeLineChartData: [],
  realTimeLineChartLabels: [],
  realTimeDonutChart: [],
  realTimeTwoLinesChartDataDone: [],
  realTimeTwoLinesChartDataCanceled: [],
  realTimeTwoLinesChartDataTotal: [],
  realTimeTwoLinesChartLabels: [],
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOTAL_IN_COURSE:
    case GET_SUMMARY_IN_COURSE:
    case GET_BALANCE:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_TOTAL_IN_COURSE_ERROR:
    case GET_SUMMARY_IN_COURSE_ERROR:
    case GET_BALANCE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_TOTAL_IN_COURSE_EXITO:
      return {
        ...state,
        realTimeLineChartData: [
          ...state.realTimeLineChartData,
          ...action.payload.data,
        ],
        realTimeLineChartLabels: [
          ...state.realTimeLineChartLabels,
          action.payload.label,
        ],
        loading: false,
      };
    case GET_SUMMARY_IN_COURSE_EXITO:
      return {
        ...state,
        realTimeDonutChart: [action.payload],
        loading: false,
      };
    case GET_BALANCE_EXITO:
      return {
        ...state,
        realTimeTwoLinesChartDataDone: [
          ...state.realTimeTwoLinesChartDataDone,
          action.payload.data.done,
        ],
        realTimeTwoLinesChartDataCanceled: [
          ...state.realTimeTwoLinesChartDataCanceled,
          action.payload.data.canceled,
        ],
        realTimeTwoLinesChartDataTotal: [
          ...state.realTimeTwoLinesChartDataTotal,
          action.payload.data.total,
        ],
        realTimeTwoLinesChartLabels: [
          ...state.realTimeTwoLinesChartLabels,
          action.payload.label,
        ],
        loading: false,
      };
    default:
      return state;
  }
}
