import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 300px;
`;

const LoadingComponent = () => {
  return (
    <>
      <PuffLoader loading="true" color="#010101" css={override} size={150} />
    </>
  );
};

export default LoadingComponent;
