import CSQLGenerator from "../containers/sql-generator/CSQLGenerator";

const SQLGenerador = () => {
  return (
    <>
      <CSQLGenerator />
    </>
  );
};

export default SQLGenerador;
