import Chart from "react-apexcharts";

const RadarChart = (props) => {
  const { data } = props;
  const settingChart = (data) => ({
    series: [
      {
        name: "Promedio Seg",
        data: [
          data.CID,
          data.CIDPago,
          // data.CIDConfirm,
          // data.CIDWebhook,
          data.WebhookConfirm,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "radar",
      },
      title: {
        text: "Radar Tiempos",
      },
      xaxis: {
        categories: [
          "CD/CID",
          "CD/WD",
          // "CID/Confirm",
          // "CID/Webhook",
          "WD/CPD",
        ],
      },
    },
  });

  return (
    <>
      {Object.values(data).length > 0 ? (
        <Chart
          options={settingChart(data).options}
          series={settingChart(data).series}
          type="radar"
          height={350}
        />
      ) : null}
    </>
  );
};

export default RadarChart;
