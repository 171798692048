import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataMetricsAction } from "../../redux/actions/MetricsAction";
import { Container, Row, Col } from "react-bootstrap";
import { parserDataSegXOps } from "../../utils";
import Menu from "../../components/metrics/Menu";
import DonutChart from "../../components/metrics/charts/DonutChart";
import ColumnChart from "../../components/metrics/charts/ColumnChart";
import RadarChart from "../../components/metrics/charts/RadarChart";

const CMetrics = () => {
  const { summaryStatus, summaryTimes, summarySegxOps, intervalUpdateMinutes } = useSelector(
    (state) => state.metrics
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getDataMetricsAction());
    }, parseInt(intervalUpdateMinutes * 60000));
    return () => clearInterval(interval);
  }, [dispatch,intervalUpdateMinutes]);

  return (
    <>
      <Menu />
      <Container className="mt-4">
        <Row>
          <Col sm={6}>
            {summaryStatus.length > 0 ? (
              <DonutChart data={summaryStatus[0]} />
            ) : null}
          </Col>
          <Col sm={6}>
            {summaryTimes.length > 0 ? (
              <RadarChart data={summaryTimes[0]} />
            ) : null}
          </Col>
        </Row>
      </Container>
      <Container>
        {summarySegxOps.length > 0 ? (
          <ColumnChart data={parserDataSegXOps(summarySegxOps)} />
        ) : null}
      </Container>
    </>
  );
};

export default CMetrics;
