import Chart from "react-apexcharts";

const DonutChart = (props) => {
  const { data } = props;

  const settingChart = (data) => ({
    series: [data.completed || 0, data.canceled || 0, data.errorYpf || 0, data.errorWs || 0],
    options: {
      chart: {
        type: "donut",
      },
      title: {
        text: "Resumen del día",
        align: "left",
      },
      fill: {
        colors: ['#43A047', '#D50000','#2196F3','#FFC107']
      },
      colors:['#43A047', '#D50000', '#2196F3','#FFC107'],
      labels: ["Completado", "Anuladas","Error YPF","Error WS"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <>
      {Object.values(data).length > 0 ? (
        <Chart
          options={settingChart(data).options}
          series={settingChart(data).series}
          type="donut"
          height={350}
        />
      ) : null}
    </>
  );
};

export default DonutChart;
