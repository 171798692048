import { Route } from "react-router-dom";

const PublicRouter = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest}>
      <Component />
    </Route>
  );
};

export default PublicRouter;