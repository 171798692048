import { Provider } from "react-redux";
import generateStore from "./redux/store";
import AppRouter from "./routers/AppRouter";

export default function App() {
  const store = generateStore();
  return (
    <>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </>
  );
}
