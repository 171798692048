import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from "cdbreact";

const Datatable = (props) => {
  const { data, columns } = props;

  const dataPrepared = (data, columns) => ({
    columns: columns,
    rows: data,
  });

  return (
    <>
      <CDBContainer>
        <CDBCard>
          <CDBCardBody>
            <CDBDataTable
              striped
              bordered
              hover
              scrollY
              maxHeight="50vh"
              data={dataPrepared(data, columns)}
              materialSearch
            />
          </CDBCardBody>
        </CDBCard>
      </CDBContainer>
    </>
  );
};

export default Datatable;
