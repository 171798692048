import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIntervalUpdate } from "../../redux/actions/DashboardAction";
import { setIntervalMinutesAction } from "../../redux/actions/MetricsAction";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import { makeStyles } from "@material-ui/styles";
import HelmetUtils from "../../shared/HelmetUtils";

const useStyle = makeStyles({
  navPosition: {
    position: "relative",
    left: -30,
  },
});

const GuessSiteActive = (path) => {
  switch (path) {
    case "/dashboard":
      return "Dashboard";
    case "/real-time":
      return "Real Time";
    case "/metricas":
      return "Métricas";
    case "/sql-generador":
      return "SQL Generador";
    case "/list-ops":
      return "Listado de Operaciones";
    case "/stations":
      return "Estaciones";
    case "/wiki":
      return "Wiki";
    case "/details-op":
      return `Detalle: ${path}`;
    default:
      if (path.includes("/details-op/")) {
        const idIntencion = path.split("/")[2];
        return `Detalle ${idIntencion}`;
      } else if (path.includes("/details-station/")) {
        const eess = path.split("/")[2];
        return `Detalle ${eess}`;
      } else {
        return "Foca YPF";
      }
  }
};

const Menu = () => {
  const { intervalUpdate } = useSelector((state) => state.dashboard);
  const {  intervalUpdateMinutes } = useSelector((state) => state.metrics);
  const dispatch = useDispatch();
  const [minutesMetrics, setMinutesMetrics] = useState(intervalUpdateMinutes);
  const [minutesDashboard, setMinutesDashboard] = useState(intervalUpdate);
  const { pathname } = useLocation();
  const classes = useStyle();

  useEffect(() => {
    dispatch(setIntervalUpdate(minutesDashboard));
    dispatch( setIntervalMinutesAction(minutesMetrics) )
  }, [minutesDashboard,minutesMetrics,dispatch]);

  return (
    <>
      <Navbar>
        <HelmetUtils title={GuessSiteActive(pathname)} />
        <Navbar.Brand>{GuessSiteActive(pathname)}</Navbar.Brand>
        <Navbar.Toggle />
        {GuessSiteActive(pathname) === "Dashboard"  ? (
          <Navbar.Collapse className="justify-content-end">
            <Nav className={classes.navPosition}>
              <Badge bg="light" text="dark">
                Actualización cada {minutesDashboard} minutos
              </Badge>
            </Nav>
            <Nav className={classes.navPosition}>
              <NavDropdown title="Actualización">
                <NavDropdown.Item onClick={() => setMinutesDashboard(1)}>
                  Cada minuto
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setMinutesDashboard(5)}>
                  Cada 5 minutos
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setMinutesDashboard(10)}>
                  Cada 10 minutos
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setMinutesDashboard(15)}>
                  Cada 15 minutos
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        ) : null}
        {
          GuessSiteActive(pathname) === "Métricas" ? (
            <Navbar.Collapse className="justify-content-end">
              <Nav className={classes.navPosition}>
                <Badge bg="light" text="dark">
                  Actualización cada {minutesMetrics} minutos
                </Badge>
              </Nav>
              <Nav className={classes.navPosition}>
                <NavDropdown title="Actualización">
                  <NavDropdown.Item onClick={() => setMinutesMetrics(1)}>
                    Cada minuto
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setMinutesMetrics(5)}>
                    Cada 5 minutos
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setMinutesMetrics(10)}>
                    Cada 10 minutos
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setMinutesMetrics(15)}>
                    Cada 15 minutos
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          ) : null
        }
        {/* <Navbar.Text>
              Signed in as: <a href="#login">Mark Otto</a>
            </Navbar.Text> */}
      </Navbar>
    </>
  );
};

export default Menu;
