import { Container } from "react-bootstrap";
import Chart from "react-apexcharts";

const LineChart = (props) => {

  const {data, labels} = props;

  const settingsChart = {
    series: [
      {
        name: "Operaciones en curso",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Total operaciones en curso",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      yaxis: {
        min: 0,
        max: 70,
      },
      xaxis: {
        categories: [],
      },
    },
  };

  settingsChart.series[0].data = [...data];
  settingsChart.options.xaxis.categories = [...labels]

  return (
    <>
      <Container>
        <Chart
          options={settingsChart.options}
          series={settingsChart.series}
          type="line"
          height={350}
        />
      </Container>
    </>
  );
};

export default LineChart;
