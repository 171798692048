import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataMetricsAction } from "../redux/actions/MetricsAction";
import CMetrics from "../containers/metrics/CMetrics";
import LoadingComponent from "../shared/LoadingComponent";

const Metrics = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.metrics);

  useEffect(() => {
    dispatch(getDataMetricsAction());
  }, [dispatch]);

  return <>{loading ? <LoadingComponent /> : <CMetrics />}</>;
};

export default Metrics;
