import {
  GET_DATA_METRICS,
  GET_DATA_METRICS_EXITO,
  GET_DATA_METRICS_ERROR,
  SET_INTERVAL_UPDATE,
  SET_INTERVAL_UPDATE_MINUTES
} from "../types/Metrics";

const initialState = {
  summaryStatus: [],
  summaryTimes: [],
  summarySegxOps: [],
  intervalUpdateMinutes:5,
  intervalUpdate: 1,
  loading: false,
  error: null,
};

export default function recuder(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_METRICS:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_DATA_METRICS_EXITO:
      return {
        ...state,
        loading: false,
        summaryStatus: [action.payload.summaryStatus],
        summaryTimes: [...action.payload.summaryTimes],
        summarySegxOps: [...action.payload.summarySegxOps],
      };
    case GET_DATA_METRICS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_INTERVAL_UPDATE:
      return {
        ...state,
        intervalUpdate: [action.payload],
      };
    case SET_INTERVAL_UPDATE_MINUTES:
        return {
          ...state,
          intervalUpdateMinutes:[action.payload]
        }
    default:
      return state;
  }
}
