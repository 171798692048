import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getTotalInCourseAction,
  getSummaryStatusInCourseAction,
  getBalanceAction,
} from "../redux/actions/RealTimeAction";
import { Container } from "react-bootstrap";
import CRealTimeCharts from "../containers/real-time/CRealTimeCharts";
const RealTime = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotalInCourseAction())
    dispatch(getSummaryStatusInCourseAction())
    dispatch(getBalanceAction())
  },[dispatch])

  return (
    <>
      <Container className="text-center mt-5">
        <CRealTimeCharts />
      </Container>
    </>
  );
};

export default RealTime;
