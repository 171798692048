import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRouter from "./PrivateRouter";
import Layout from "../layout/Layout";
import Dashboard from "../pages/Dashboard";
import RealTime from "../pages/RealTime";
import DetailsErrorWs from "../pages/DetailsErrorWs";
import Metrics from "../pages/Metrics";
import SQLGenerator from "../pages/SQLGenerador";

const AppRouter = () => {
  return (
    <>
      <Router>
        <Switch>
          <Layout>
            <PrivateRouter exact path="/dashboard" component={Dashboard} />
            <PrivateRouter exact path="/real-time" component={RealTime} />
            <PrivateRouter exact path="/metricas" component={Metrics} />
            <PrivateRouter exact path="/sql-generador" component={SQLGenerator} />
            <PrivateRouter exact path="/details/errors-ws" component={DetailsErrorWs} />
             <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
          </Layout>
        </Switch>
      </Router>
    </>
  );
};

export default AppRouter;
