import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTotalInCourseAction,
  getSummaryStatusInCourseAction,
  getBalanceAction,
} from "../../redux/actions/RealTimeAction";
import { Container, Row, Col } from "react-bootstrap";
import LineChart from "../../components/real-time/LineChart";
import DonutChart from "../../components/real-time/DonutChart";
import TwoLinesChart from "../../components/real-time/TwoLinesChart";
const CRealTimeCharts = () => {
  const {
    realTimeLineChartData,
    realTimeLineChartLabels,
    realTimeDonutChart,
    realTimeTwoLinesChartDataDone,
    realTimeTwoLinesChartDataCanceled,
    realTimeTwoLinesChartDataTotal,
    realTimeTwoLinesChartLabels,
  } = useSelector((state) => state.realTime);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getTotalInCourseAction());
      dispatch(getSummaryStatusInCourseAction());
      dispatch(getBalanceAction());
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <>
      <Container>
        <Row>
          <Col sm={8}>
            <LineChart
              data={realTimeLineChartData}
              labels={realTimeLineChartLabels}
            />
          </Col>
          <Col sm={4}>
            <DonutChart data={realTimeDonutChart} />
          </Col>
        </Row>
      </Container>
      <Container className="mt-2">
        <TwoLinesChart
          done={realTimeTwoLinesChartDataDone}
          canceled={realTimeTwoLinesChartDataCanceled}
          total={realTimeTwoLinesChartDataTotal}
          labels={realTimeTwoLinesChartLabels}
        />
      </Container>
    </>
  );
};

export default CRealTimeCharts;
