import ExportExcel from "react-export-excel";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

const ExportExcelFactory = ({ element, data }) => {
  return (
    <>
      <ExcelFile element={element} filename="export">
        <ExcelSheet data={data} name="operaciones">
            <ExcelColumn label="id" value="id"/>
            <ExcelColumn label="fuelStationId" value="fuelStationId"/>
            <ExcelColumn label="pointOfSale" value="pointOfSale"/>
            <ExcelColumn label="saleType" value="saleType"/>
            <ExcelColumn label="idpump" value="idpump"/>
            <ExcelColumn label="csu" value="csu"/>
            <ExcelColumn label="contador" value="contador"/>
            <ExcelColumn label="idAzure" value="idAzure"/>
            <ExcelColumn label="creationDate" value="creationDate"/>
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

export default ExportExcelFactory;
