import Chart from "react-apexcharts";

const ColumnChart = (props) => {
  const { data } = props;
  const settingsChart = (data) => ({
    series: [
      {
        name: "Total",
        data: [...data[0]],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [...data[1]],
      },
      yaxis: {
        title: {
          text: "Total Operaciones",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " operaciones";
          },
        },
      },
    },
  });

  return (
    <>
      {Object.values(data).length > 0 ? (
        <Chart
          options={settingsChart(data).options}
          series={settingsChart(data).series}
          type="bar"
          height={350}
        />
      ) : null}
    </>
  );
};

export default ColumnChart;
