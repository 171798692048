import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataDashboardAction } from "../../redux/actions/DashboardAction";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import DonutChart from "../../components/dashboard/charts/DonutChart";
import LineChart from "../../components/dashboard/charts/LineChart";
import RadarChart from "../../components/dashboard/charts/RadarChart";
import CardCounter from "../../components/dashboard/CardCounter";

const CDashboard = () => {
  const [isLoading, setLoading] = useState(false);
  const { summaryHours, summaryStatus, summaryTimes, intervalUpdate, updated } = useSelector(
    (state) => state.dashboard
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(async () => {
      setLoading(true);
      await dispatch(getDataDashboardAction());
      setLoading(false);
    }, parseInt(intervalUpdate * 60000));
    return () => clearInterval(interval);
  }, [intervalUpdate, dispatch]);

  return (
    <>
      {isLoading ? (
        <Container className="d-flex justify-content-end mt-2">
          <Spinner animation="border" variant="secondary" />
        </Container>
      ) : null}

      <Container className="mt-3">
        <Row>
          <CardCounter
            name={"Total"}
            data={summaryStatus.length > 0 ? summaryStatus[0].total : 0}
            lastUpdated={updated}
          />
          <CardCounter
            name={"Completado"}
            data={summaryStatus.length > 0 ? summaryStatus[0].completed : 0}
            lastUpdated={updated}
          />
          <CardCounter
            name={"Anuladas"}
            data={summaryStatus.length > 0 ? summaryStatus[0].canceled : 0}
            lastUpdated={updated}
          />
          <CardCounter
            name={"Error WS"}
            data={summaryStatus.length > 0 ? summaryStatus[0].errorWs : 0}
            lastUpdated={updated}
          />
          <CardCounter
            name={"Error YPF"}
            data={summaryStatus.length > 0 ? summaryStatus[0].errorYpf : 0}
            lastUpdated={updated}
          />
        </Row>
      </Container>
      <Container className="mt-3">
        <LineChart data={summaryHours} />
      </Container>
      <Container className="mt-3">
        <Row>
          <Col xs={6}>
            {
              summaryStatus.length > 0 ? (
                <DonutChart data={summaryStatus[0]}/>
              ) : null
            }
          </Col>
          <Col xs={6}>
              <RadarChart data={summaryTimes.length > 0 ? summaryTimes[0]: {}}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CDashboard;
