import Chart from "react-apexcharts";

const DonutChart = (props) => {
  const { data } = props;
  const settingsChart = (data) => ({
    series: [
      data.completed || 0,
      data.canceled || 0,
      data.errorYpf || 0,
      data.errorWs || 0,
    ],
    options: {
      labels: ["Completado", "Anuladas", "Error YPF", "Error WS"],
      fill: {
        colors: ["#43A047", "#D50000", "#2196F3", "#FFC107"],
      },
      colors: ["#43A047", "#D50000", "#2196F3", "#FFC107"],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <>
      <Chart
        options={settingsChart(data).options}
        series={settingsChart(data).series}
        type="donut"
        height={350}
      />
    </>
  );
};

export default DonutChart;
