import moment from "moment";

export const rangeHoursDay = () => {
  return [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "23:00",
    "23:00",
  ];
};

export const addHandleDetailsListOps = (data, functionHandler,history) => {
  const dataPrepared = data.map((item) => {
    item["clickEvent"] = () => functionHandler(item.id,history)
    return item;
  })
  return dataPrepared;
}

export const addHandleDetailsListStations = (data, functionHandler,history) => {
  const dataPrepared = data.map((item) => {
    item["clickEvent"] = () => functionHandler(item.eess,history)
    return item;
  })
  return dataPrepared;
}

export const sortDataSeriesLineChart = (data) => {
  const sortArray = [];
  data.forEach(el => {
    if (el.name === "Éxito") {
      sortArray[0] = {
        name:"Completado",
        data:el.data
      }
    }
    if (el.name === "Anuladas") {
      sortArray[1] = {
        name:"Anuladas",
        data:el.data
      }
    }
    if (el.name === "Error WS") {
      sortArray[2] = {
        name:"Error WS",
        data:el.data
      }
    }
    if (el.name === "Error YPF") {
      sortArray[3] = {
        name:"Error YPF",
        data:el.data
      }
    }
  });
  return sortArray;
}

export const guessInterval = (interval) => {
  if (interval[0] === 30) {
    const start = moment().subtract(30, "minutes").format("HH:mm:ss");
    const end = moment().format("HH:mm:ss");
    return [start,end];
  }else{
    const start = moment().subtract(interval, "hours").format("HH:mm:ss");
    const end = moment().format("HH:mm:ss");
    return [start,end];
  }
}


export const parserDataSegXOps = (data) => {
  const arrayData = [];
  const arrayLabels = [];

  data.forEach(el => {
    arrayData.push(el.cantidad);
    arrayLabels.push(el.segudos);
  });

  return [arrayData, arrayLabels];

}

export const parserColumnDatatable = (data) => {
  const columns = [];
  const keys = Object.keys(data[0])
  
  keys.forEach((item) => {
    let obj = {};
    obj['label'] = item;
    obj['field'] = item;
    obj['width'] = 150;
    columns.push(obj);
  });

  return columns;
}
