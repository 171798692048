import {
  GET_DATA_DASHBOARD,
  GET_DATA_DASHBOARD_EXITO,
  GET_DATA_DASHBOARD_ERROR,
  SET_INTERVAL_MINUTES_FOR_UPDATE,
} from "../types/Dashboard";
import { getCountByStatus,getCountByHours,getIntervals } from "../../services/ApiServices";

export function getDataDashboardAction() {
  return async (dispatch) => {
    dispatch(getDataDashboard());

    try {
      const summaryStatus = await getCountByStatus();
      const summaryHours = await getCountByHours();
      const summaryIntervals = await getIntervals();
      dispatch(getDataDashboardExito(summaryStatus, summaryHours,summaryIntervals));
    } catch (error) {
      console.error(error.message);
      dispatch(getDataDashboardError());
    }
  };
}

export function setIntervalUpdate(minutes) {
  return async (dispatch) => {
    dispatch(setIntervalMinutes(minutes));
  };
}

const getDataDashboard = () => ({
  type: GET_DATA_DASHBOARD,
  payload: true,
});

const getDataDashboardExito = (summaryStatus, summaryHours,summaryIntervals) => ({
  type: GET_DATA_DASHBOARD_EXITO,
  payload: {
    summaryStatus,
    summaryHours,
    summaryIntervals,
  },
});

const getDataDashboardError = () => ({
  type: GET_DATA_DASHBOARD_ERROR,
  payload: true,
});

const setIntervalMinutes = (minutes) => ({
  type: SET_INTERVAL_MINUTES_FOR_UPDATE,
  payload:minutes
});
