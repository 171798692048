import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from "cdbreact";

const Datatable = (props) => {

  const { data } = props;

  const dataPrepared = (data) => ({
    columns: [
      {
        label: "id",
        field: "id",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "ID",
        },
      },
      {
        label: "fuelStationId",
        field: "fuelStationId",
        width: 270,
      },
      {
        label: "pointOfSale",
        field: "pointOfSale",
        width: 200,
      },
      {
        label: "saleType",
        field: "saleType",
        sort: "disabled",
        width: 100,
      },
      {
        label: "idpump",
        field: "idpump",
        sort: "disabled",
        width: 150,
      },
      {
        label: "csu",
        field: "csu",
        sort: "disabled",
        width: 100,
      },
      {
        label: "contador",
        field: "contador",
        sort: "disabled",
        width: 100,
      },
      {
        label: "idAzure",
        field: "idAzure",
        sort: "disabled",
        width: 100,
      },
      {
        label: "creationDate",
        field: "creationDate",
        sort: "disabled",
        width: 100,
      },
      {
        label: "creationIntencionDate",
        field: "creationIntencionDate",
        sort: "disabled",
        width: 100,
      },
      {
        label: "webhookDate",
        field: "webhookDate",
        sort: "disabled",
        width: 100,
      },
      {
        label: "confirmPaymentFuelStationDate",
        field: "confirmPaymentFuelStationDate",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: data
  });

  return (
    <>
      <CDBContainer>
        <CDBCard>
          <CDBCardBody>
            <CDBDataTable
              striped
              bordered
              hover
              scrollY
              maxHeight="50vh"
              data={dataPrepared(data)}
              materialSearch
            />
          </CDBCardBody>
        </CDBCard>
      </CDBContainer>
    </>
  );
};

export default Datatable;
