import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-beautify";

import "ace-builds/webpack-resolver";

const Editor = ({query, setQuery}) => {
  return (
    <>
      <AceEditor
        placeholder="Ingrese la consulta a realizar"
        mode="sql"
        theme="textmate"
        name="basic-code-editor"
        style={{
          width: "100%",
          height: 318,
        }}
        onChange={(currentCode) => setQuery(currentCode)}
        fontSize={18}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={query}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 4,
        }}
      />
    </>
  );
};

export default Editor;
