import {
  GET_TOTAL_IN_COURSE,
  GET_TOTAL_IN_COURSE_EXITO,
  GET_TOTAL_IN_COURSE_ERROR,
  GET_SUMMARY_IN_COURSE,
  GET_SUMMARY_IN_COURSE_EXITO,
  GET_SUMMARY_IN_COURSE_ERROR,
  GET_BALANCE,
  GET_BALANCE_EXITO,
  GET_BALANCE_ERROR,
} from "../types/RealTime";
import {
  getTotalInCourse as getTotalInCourseFetch,
  getSummaryStatusInCourse as getSummaryStatusInCourseFetch,
  getBalance as getBalanceFetch,
} from "../../services/ApiServices";
import moment from "moment";

export function getTotalInCourseAction() {
  const label = moment().format("HH:mm").split(" ")[0];
  return async (dispatch) => {
    dispatch(getTotalInCourse());
    try {
      const data = await getTotalInCourseFetch(label);
      dispatch(getTotalInCourseExito(Object.values(data), label));
    } catch (error) {
      console.error(error.message);
      dispatch(getTotalInCourseError());
    }
  };
}

export function getSummaryStatusInCourseAction() {
  const label = moment().format("HH:mm").split(" ")[0];
  return async (dispatch) => {
    dispatch(getSummaryInCourse());
    try {
      const data = await getSummaryStatusInCourseFetch(label);
      dispatch(getSummaryInCourseExito(data));
    } catch (error) {
      console.error(error.message);
      dispatch(getSummaryInCourseError());
    }
  };
}

export function getBalanceAction() {
  const startIn = moment().subtract(5, "minutes").format("HH:mm");
  return async (dispatch) => {
    dispatch(getBalance());
    try {
      const data = await getBalanceFetch(startIn);
      dispatch(getBalanceExito(data, startIn));
    } catch (error) {
      console.error(error.message);
      dispatch(getBalanceError());
    }
  };
}

// GET_TOTAL_IN_COURSE ACTIONS
const getTotalInCourse = () => ({
  type: GET_TOTAL_IN_COURSE,
  payload: true,
});

const getTotalInCourseExito = (data, label) => ({
  type: GET_TOTAL_IN_COURSE_EXITO,
  payload: {
    data,
    label,
  },
});

const getTotalInCourseError = () => ({
  type: GET_TOTAL_IN_COURSE_ERROR,
  payload: true,
});

// GET_SUMMARY_IN_COURSE ACTIONS
const getSummaryInCourse = () => ({
  type: GET_SUMMARY_IN_COURSE,
  payload: true,
});

const getSummaryInCourseExito = (data) => ({
  type: GET_SUMMARY_IN_COURSE_EXITO,
  payload: data,
});

const getSummaryInCourseError = () => ({
  type: GET_SUMMARY_IN_COURSE_ERROR,
  payload: true,
});

// GET_BALANCE ACTIONS
const getBalance = () => ({
  type: GET_BALANCE,
  payload: true,
});

const getBalanceExito = (data, label) => ({
  type: GET_BALANCE_EXITO,
  payload: {
    data,
    label,
  },
});

const getBalanceError = () => ({
  type: GET_BALANCE_ERROR,
  payload: true,
});
