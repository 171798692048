import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { Container, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/styles";
import { NavLink } from "react-router-dom";

const useStyle = makeStyles({
  sidebarRoot: {
    display: "flex",
    height: "100vh",
    overflow: "scroll initial",
  },
  sectionNavbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  bottomNavbar: {
    borderBottom: "1px solid #cecece",
    boxShadow: "0 4px 2px -2px gray"
  },
});

const Sidebar = ({ children }) => {
  const classes = useStyle();

  return (
    <div className={classes.sidebarRoot}>
      <CDBSidebar textColor="#fff" backgroundColor="#000">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          Dashboard YPF
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
              <NavLink exact to="/dashboard" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/real-time" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line">Real time</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/metricas" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="clock">Métricas</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/sql-generador" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="database">SQL Generator</CDBSidebarMenuItem>
              </NavLink>
              {/* <NavLink exact to="/list-ops" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="table">Lista de Operaciones</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/stations" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="store">
                  Estaciones
                </CDBSidebarMenuItem>
              </NavLink> */}
              {/* <NavLink exact to="/wiki" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="exclamation-circle">
                  Wiki
                </CDBSidebarMenuItem>
              </NavLink> */}
          </CDBSidebarMenu>
        </CDBSidebarContent>

      </CDBSidebar>
      <Container fluid className={classes.sectionNavbar}>
        <Col className={classes.bottomNavbar}>{children[0]}</Col>
        <Col>
          <Container fluid>{children[1]}</Container>
        </Col>
      </Container>
    </div>
  );
};

export default Sidebar;
