import { Container } from "react-bootstrap";
import Chart from "react-apexcharts";

const DonutChart = (props) => {
  const { data } = props;
  const settingChart = {
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      title: {
        text: "Desglose de estatus en curso",
        align: "left",
      },
      // labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  if(data.length > 0) {
    settingChart.series = [...Object.values(data[0])];  
    settingChart.options.labels = [...Object.keys(data[0])];
  }

  return (
    <>
      <Container>
        <Chart
          options={settingChart.options}
          series={settingChart.series}
          type="donut"
          height={350}
        />
      </Container>
    </>
  );
};

export default DonutChart;
