import Sidebar from "./components/Sidebar";
import Menu from "./components/Menu";

const Layout = ({ children }) => {
  return (
    <>
      <Sidebar>
        <Menu />
        {children}
      </Sidebar>
    </>
  );
};

export default Layout;
