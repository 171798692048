//TOTAL_IN_COURSE
export const GET_TOTAL_IN_COURSE = "GET_TOTAL_IN_COURSE";
export const GET_TOTAL_IN_COURSE_EXITO = "GET_TOTAL_IN_COURSE_EXITO";
export const GET_TOTAL_IN_COURSE_ERROR = "GET_TOTAL_IN_COURSE_ERROR";

//SUMMARY_IN_COURSE
export const GET_SUMMARY_IN_COURSE = "GET_SUMMARY_IN_COURSE";
export const GET_SUMMARY_IN_COURSE_EXITO = "GET_SUMMARY_IN_COURSE_EXITO";
export const GET_SUMMARY_IN_COURSE_ERROR = "GET_SUMMARY_IN_COURSE_ERROR";

//BALANCE
export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_EXITO = "GET_BALANCE_EXITO";
export const GET_BALANCE_ERROR = "GET_BALANCE_ERROR";
