import { Card, Col, Row } from "react-bootstrap";
import { BsArrowClockwise } from "react-icons/bs";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import {
  FcRating,
  FcCheckmark,
  FcMediumPriority,
  FcInfo,
  FcCancel,
} from "react-icons/fc";
import moment from "moment";

const SelectedIcon = ({ name }) => {
  const styles = { width: 70, height: 70 };
  switch (name) {
    case "Total":
      return <FcRating style={styles} />;
    case "Completado":
      return <FcCheckmark style={styles} />;
    case "Anuladas":
      return <FcCancel style={styles} />;
    case "Error WS":
      return <FcMediumPriority style={styles} />;
    case "Error YPF":
      return <FcInfo style={styles} />;
    default:
      return "";
  }
};

const useStyle = makeStyles({
  pointer: {
    cursor: "pointer",
  },
});

const CardCounter = (props) => {
  const { name, data, lastUpdated } = props;
  const history = useHistory();
  const classes = useStyle();

  return (
    <>
      <Col>
        <Card
          className={name === "Error WS" ? classes.pointer : ""}
          onClick={() =>
            name === "Error WS" ? history.push("/details/errors-ws") : null
          }
        >
          <Card.Body>
            <Row>
              <Col className="text-center">
                <SelectedIcon name={name} />
              </Col>
              <Col className="d-flex justify-content-center">
                <Row>
                  <h6 className="text-center text-muted">{name}</h6>
                  <h5 className="text-center">{data || 0}</h5>
                </Row>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <span className="text-muted">
              <BsArrowClockwise />{" "}
              {moment(lastUpdated).startOf("minutes").fromNow()}
            </span>
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};

export default CardCounter;
