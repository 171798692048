import {
  GET_DATA_DASHBOARD,
  GET_DATA_DASHBOARD_EXITO,
  GET_DATA_DASHBOARD_ERROR,
  SET_INTERVAL_MINUTES_FOR_UPDATE,
} from "../types/Dashboard";

const initialState = {
  intervalUpdate: 5,
  summaryStatus: [],
  summaryHours: [],
  summaryTimes: [],
  hasData: false,
  loading: false,
  updated: null,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_DASHBOARD:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_DATA_DASHBOARD_EXITO:
      return {
        ...state,
        summaryStatus: [...action.payload.summaryStatus],
        summaryHours: [...action.payload.summaryHours],
        summaryTimes: [...action.payload.summaryIntervals],
        hasData: true,
        loading: false,
        updated: Date.now(),
      };
    case GET_DATA_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_INTERVAL_MINUTES_FOR_UPDATE:
      return {
        ...state,
        intervalUpdate: action.payload,
      };
    default:
      return state;
  }
}
