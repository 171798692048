import { useState, useEffect } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { FcDownload } from "react-icons/fc";
import { getFullTables, getQuery } from "../../services/ApiServices";
import { parserColumnDatatable } from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import Editor from "../../components/sql-generator/Editor";
import ListTable from "../../components/sql-generator/ListTable";
import Datatable from "../../components/sql-generator/Datatable";
import ExportExcelQuery from "../../shared/ExportExcelQuery";
import "react-toastify/dist/ReactToastify.css";

const CSQLGenerator = () => {
  const [tables, setTables] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const data = await getFullTables();
      setTables(data);
    };
    fetchData();
  }, []);

  const geData = async () => {
    setLoading(true);
    try {
      const data = await getQuery(query);
      if (data.length > 0) {
        setLoading(false);
        setData(data);
      } else {
        toast("Sin resultado");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast(`Query no valido`);
    }
  };

  return (
    <>
      <Container fluid className="mt-3">
        <Row>
          <Col sm={4}>
            <ListTable data={tables} />
          </Col>
          <Col sm={8}>
            <Editor setQuery={setQuery} query={query} />
            <Container className="text-center mt-2">
              <Button
                variant="outline-dark"
                disabled={isLoading}
                size="lg"
                onClick={!isLoading ? geData : null}
              >
                {isLoading ? <Spinner animation="border" /> : "Ejecutar"}
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-2">
        {data.length > 0 ? (
          <Container className="mb-2">
            <ExportExcelQuery
              data={data}
              labels={parserColumnDatatable(data)}
              element={
                <Button variant="outline-success" className="mr-3">
                  Exportar a Excel <FcDownload />
                </Button>
              }
            />
          </Container>
        ) : null}
        <Datatable
          data={data}
          columns={data.length > 0 ? parserColumnDatatable(data) : []}
        />
      </Container>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CSQLGenerator;
