import Chart from "react-apexcharts";

const TwoLinesChart = (props) => {
  const { done, canceled, total, labels } = props;
  const settingChart = {
    series: [
      {
        name: "Completadas",
        type: 'line',
        data: [],
      },
      {
        name: "Anuladas",
        type: 'line',
        data: [],
      },
      {
        name: "Total",
        type: 'column',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#4CF787", "#FA544C","#008FFB"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Balance de éxito",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {},
      yaxis: {
        min: 5,
        max: 100,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  };

  settingChart.series[0].data = done;
  settingChart.series[1].data = canceled;
  settingChart.series[2].data = total;

  settingChart.options.xaxis.categories = labels;

  return (
    <>
      <Chart
        options={settingChart.options}
        series={settingChart.series}
        type="line"
        height={350}
      />
    </>
  );
};

export default TwoLinesChart;
