import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FcDownload, FcLeft } from "react-icons/fc";
import { Container, Button } from "react-bootstrap";
import { getErrorWs } from "../services/ApiServices";
import Datatable from "../components/list-ops/Datatable";
import ExportExcelFactory from "../shared/ExportExcelListOps";
import HelmetUtils from "../shared/HelmetUtils";

const DetailsErrorWs = () => {
  const [data, setData] = useState([]);
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getErrorWs();
      setData(result);
    };
    fetchData();
  }, []);

  return (
    <>
    <HelmetUtils title={"Errors WS"}/>
      <Container className="mt-5">
        <Button className="ml-3" variant="outline-primary" onClick={handleBack}>
          <FcLeft /> Volver
        </Button>
      </Container>
      <Container className="text-center">
        <h3>Lista de Errores WS</h3>
      </Container>
      <Container className="d-flex justify-content-end mt-3">
        <ExportExcelFactory
          element={
            <Button variant="outline-success" className="mr-3">
              Exportar a Excel <FcDownload />
            </Button>
          }
          data={data}
        />
      </Container>
      <Container className="mt-5">
        <Datatable data={data} />
      </Container>
    </>
  );
};

export default DetailsErrorWs;
