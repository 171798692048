import clientAxios from "../config/clientAxios";

export const getCountByStatus = async () => {
  const { data } = await clientAxios.get("/summary-status");
  return data;
};

export const getCountByHours = async () => {
  const { data } = await clientAxios.get("/summary-hours");
  return data;
};

export const getIntervals = async() => {
  const { data } = await clientAxios.get("/summary-intervals");
  return data;
}

export const getErrorWs = async() => {
  const { data } = await clientAxios.get("/errors-ws");
  return data;
}

export const getTotalInCourse = async (time) => {
  const { data } = await clientAxios.get(`/total-in-course?minute=${time}`);
  return data;
};

export const getSummaryStatusInCourse = async (time) => {
  const { data } = await clientAxios.get(`/summary-in-course?minute=${time}`);
  return data;
};

export const getBalance = async (startIn) => {
  const { data } = await clientAxios.get(`/balance?startIn=${startIn}`);
  return data;
};

export const getSummaryStatusMetrics = async(start,end) => {
  const { data } = await clientAxios.get(`/summary-status-metrics?start=${start}&end=${end}`);
  return data;
}

export const getSummaryIntervalsMetrics = async(start,end) => {
  const { data } = await clientAxios.get(`/summary-intervals-metrics?start=${start}&end=${end}`);
  return data;
}

export const getSummaryMetrics = async(start,end) => {
  const { data } = await clientAxios.get(`/summary-metrics?start=${start}&end=${end}`);
  return data;
}

export const getFullTables = async() => {
  const { data } = await clientAxios.get("/show-full-table");
  return data;
}

export const getQuery = async(query) => {
  const { data } = await clientAxios.post("/query-exec", {
    query:`${query}`
  });
  return data; 
}

export const getDataOps = async () => {
  const { data } = await clientAxios.get(`/list-ops`);
  return data;
};

export const getDataStations = async () => {
  const { data } = await clientAxios.get(`/list-stations`);
  return data;
};

export const getDataStation = async (eess) => {
  const { data } = await clientAxios.get(`/stations/details/${eess}`);
  return data;
};

export const getDataOperacion = async (idIntencion) => {
  const { data } = await clientAxios.get(`/details/${idIntencion}`);
  return data;
};
