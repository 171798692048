import ExportExcel from "react-export-excel";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

const ExportExcelFactory = ({ element, data, labels }) => {
  return (
    <>
      <ExcelFile element={element} filename="export">
        <ExcelSheet data={data} name="result">
          {labels.map((item) => (
            <ExcelColumn key={item.field} label={item.field} value={item.field} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

export default ExportExcelFactory;
