import axios from "axios";
import { apiUrl, client_id, client_secret } from "../settings/settings.json";

const clientAxios = axios.create({
  baseURL: apiUrl,
  headers: {
    ['client-id']: client_id,
    ['client-secret']: client_secret,
  }
});

export default clientAxios;
