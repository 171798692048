import Chart from "react-apexcharts";
import { rangeHoursDay,sortDataSeriesLineChart } from "../../../utils";

const LineChart = (props) => {
  const { data } = props;

  const settingsChart = (data) => ({
    series: [...sortDataSeriesLineChart(data)],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Operaciones por hora",
        align: "left",
      },
      fill: {
        colors: ['#43A047', '#D50000', '#FFC107','#2196F3']
      },
      colors:['#43A047', '#D50000', '#FFC107','#2196F3'],
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: rangeHoursDay(),
      },
    },
  });

  return (
    <>
      <Chart
        options={settingsChart(data).options}
        series={settingsChart(data).series}
        type="line"
        height={250}
      />
    </>
  );
};

export default LineChart;
