import {
  GET_DATA_METRICS,
  GET_DATA_METRICS_EXITO,
  GET_DATA_METRICS_ERROR,
  SET_INTERVAL_UPDATE,
  SET_INTERVAL_UPDATE_MINUTES
} from "../types/Metrics";
import {
  getSummaryStatusMetrics,
  getSummaryIntervalsMetrics,
  getSummaryMetrics,
} from "../../services/ApiServices";
import { guessInterval } from "../../utils";

export function getDataMetricsAction() {
  return async (dispatch, getState) => {
    const { intervalUpdate } = getState().metrics;
    const [start, end] = guessInterval(intervalUpdate);
    dispatch(getDataMetrics());
    try {
      const summaryStatus = await getSummaryStatusMetrics(start, end);
      const summaryIntervals = await getSummaryIntervalsMetrics(start, end);
      const summarySegxOps = await getSummaryMetrics(start, end);
      dispatch(
        getDataMetricsExito(summaryStatus, summaryIntervals, summarySegxOps)
      );
    } catch (error) {
      console.error(error.message);
      dispatch(getDataMetricsError());
    }
  };
}

export function setIntervalAction(interval) {
  return async (dispatch) => {
    dispatch(setInterval(interval));
  };
}

export function setIntervalMinutesAction(interval) {
  return async (dispatch) => {
    dispatch( setIntervalMinutes(interval) );
  }
}

const getDataMetrics = () => ({
  type: GET_DATA_METRICS,
  payload: true,
});

const getDataMetricsExito = (summaryStatus, summaryTimes, summarySegxOps) => ({
  type: GET_DATA_METRICS_EXITO,
  payload: {
    summaryStatus,
    summaryTimes,
    summarySegxOps,
  },
});

const getDataMetricsError = () => ({
  type: GET_DATA_METRICS_ERROR,
  payload: true,
});

const setInterval = (interval) => ({
  type: SET_INTERVAL_UPDATE,
  payload: interval,
});

const setIntervalMinutes = (interval) => ({
  type: SET_INTERVAL_UPDATE_MINUTES,
  payload:interval
})