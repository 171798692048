import { Table } from "react-bootstrap";

const ListTable = ({data}) => {
  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Tables_in_appypf</th>
            <th>Table_type</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? data.map((item) => (
                <tr key={item.Tables_in_appypf}>
                  <td>{item.Tables_in_appypf}</td>
                  <td>{item.Table_type}</td>
                </tr>
            ))
            : null}
        </tbody>
      </Table>
    </>
  );
};

export default ListTable;
