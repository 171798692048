import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataDashboardAction } from "../redux/actions/DashboardAction";
import CDashboard from "../containers/dashboard/CDashboard";
import LoadingComponent from "../shared/LoadingComponent";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { hasData, loading } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (!hasData) {
      dispatch(getDataDashboardAction());
    }
  }, [dispatch, hasData]);

  return <>{loading ? <LoadingComponent /> : <CDashboard />}</>;
};

export default Dashboard;
