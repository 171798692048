import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIntervalAction } from "../../redux/actions/MetricsAction";
import {
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Badge,
} from "react-bootstrap";
const Menu = () => {
  const dispatch = useDispatch();
  const { intervalUpdate } = useSelector(state => state.metrics)
  const [interval, setInterval] = useState(intervalUpdate);

  useEffect(() => {
    dispatch( setIntervalAction (interval));
  },[dispatch,interval])

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-between">
          <Col sm={2}>
            <DropdownButton
              title="Rango de actualización"
              variant="outline-dark"
              drop="end"
            >
              <Dropdown.Item onClick={() => setInterval(30)}>
                30 Minutos
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setInterval(1)}>
                1 Hora
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setInterval(2)}>
                2 Horas
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setInterval(5)}>
                5 Horas
              </Dropdown.Item>
            </DropdownButton>
            <Badge className="mt-3" bg="light" text="dark">
              <h6>
                Rango de actualización:{" "}
                {interval > 5
                  ? `Últimos ${interval} Minutos`
                  : interval === 1
                  ? `Última Hora`
                  : `Últimas ${interval} Horas`}{" "}
              </h6>
            </Badge>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Menu;
